import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"

const DonePage = ({ location }) => (
  <Layout>
    <SEO title="THACO | Registration Completed" name="done" />
    <Header page="about" />
    <section
      className="pb-5 text-center text-white"
      style={{ paddingTop: 150 }}
    >
      <div className="container" style={{ maxWidth: 800 }}>
        <h2 className="mb-5">
          <span className="bg-dark px-2 py-1">การสมัครสำเร็จ</span>
        </h2>
        <h5 className="sans">
          คุณได้สมัครเข้าร่วมการแข่งขันอย่างสมบูรณ์แล้ว ชื่อผู้ใช้ของคุณคือ
        </h5>
        <h3
          className="bg-dark px-2 py-1 monospace"
          style={{ fontWeight: "bold" }}
        >
          {location?.state?.username}
        </h3>
        <h5 className="sans">
          คุณสามารถใช้เกรดเดอร์ทดสอบระบบได้ที่ <br />
          <a className="sans pb-0" href="https://contest.thaco.tech">
            https://contest.thaco.tech
          </a>
        </h5>
      </div>
    </section>
    <Footer />
  </Layout>
)

export default DonePage
